import React, {useState} from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Hero from './Hero';
import HeroLogo from './HeroLogo';

export default function Welcome() {
    return (
      <StaticQuery
        query={graphql`
          query HeadingQuery {
            file(relativePath: { eq: "Unitedo7frontBW.jpg" }) {
              childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 1980, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => (
          <div className="welcome flex column center">
            <Hero
              fluid={data.file.childImageSharp.fluid}
              posY="50%"
              posX="100%"
              height="100vh"
              width="100%"
              />
              <HeroLogo />
          </div>
        )}
      />
    )
  }
