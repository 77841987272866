import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Hero from './Hero';
import DownloadPositions from "./DownloadPositions";

const ImageBreaker3 = () => {
  return (
    <StaticQuery
      query={graphql`
        query ImageBreaker3Query {
          file(relativePath: { eq: "seamap.jpg" }) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fluid(maxWidth: 1440, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
      <div className="stayUpdated imagebreaker">
        <Hero
          fluid={data.file.childImageSharp.fluid}
          posY="0%"
          posX="12%"
          height="50vh"
          width="100%"
          />
          <div className="flex center column basePad" style={{paddingBottom: '0'}}>
            <h2>Position list</h2>
            <p style={{textAlign: 'center'}}>Stay updated - sign up on our world wide positions lists! In order to receive our world wide position lists from your local branch office, please sign up here!
            </p>
            {/* <DownloadPositions />*/}
          </div>
        </div>
      )
    }
  />
)}
export default ImageBreaker3;
