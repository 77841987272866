import React, {useState} from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import {navigate} from 'gatsby-link';
import Logo from '../../../content/img/Logo.png';
import Img from "gatsby-image/withIEPolyfill";
import { useSpring, animated, config } from 'react-spring';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Newsletter = () => {
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    company: '',
    location: ''
  });
  const handleInputChange = (event) => {
    event.persist();
    setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
  }
  const [checked, setChecked] = useState(0);
  const handleCheckbox = () => setChecked(!checked);

  const [sent, setSent] = useState(0);
  const handleSent = () => {
    setSent(!sent);
  }
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': "newsletter",
        checked,
        ...inputs
      }),
    })
      .then(() => {
        handleSent();
        e.target.reset();
        setInputs({
          name: '',
          email: '',
          company: ''
        });
        setChecked(0)
      })
      .catch((error) => alert(error))
  }

  return (
    <StaticQuery
      query={graphql`
        query NewsletterQuery {
          file(relativePath: { eq: "united07logo.png" }) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fixed(width: 200, quality: 90) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => (
      <div className="stayUpdated newsletter flex center basePad" style={{width: '100%', paddingTop: '2rem'}}>
        {/*<Img fixed={data.file.childImageSharp.fixed} style={{width: '200px'}}/>*/}
        <div className="newsletterSignup flex center column basePad">
          <form
            name="newsletter"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
            >
            <input type="hidden" name="form-name" value="newsletter" />
            <input type="hidden" name="bot-field" />
            <input
              type="text"
              name="name"
              placeholder="name"
              required
              value={inputs.name}
              onChange={handleInputChange} value={inputs.name}
            />
            <input
              type="email"
              name="email"
              placeholder="email"
              required
              value={inputs.email}
              onChange={handleInputChange}
              />
            <input
              type="text"
              name="company"
              placeholder="company"
              required
              value={inputs.company}
              onChange={handleInputChange}
              />
              <input
                type="text"
                name="location"
                placeholder="country"
                required
                value={inputs.location}
                onChange={handleInputChange}
                />

            <div className="flex center column" style={{paddingTop: '1rem'}}>
              <div className="flex center column">
                <label>I accept this <Link to="/impressum-and-data-security" target="_blank" style={{textDecoration: 'underline'}}>Privacy Policy</Link></label>
                <input
                  type="checkbox"
                  name="acceptance"
                  required
                  onChange={handleCheckbox}
                  />
                </div>
              </div>
            {
              sent ? <div className="button" style={{background: '#222'}}><h3>Thank you</h3></div> :
              <button type="submit"><h3>Send</h3></button>
            }
          </form>
        </div>
      </div>
    )
    }
    />
  )
}
export default Newsletter;
