import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Hero from './Hero';

const ImageBreaker4 = () => {
  return (
    <StaticQuery
      query={graphql`
        query ImageBreaker4Query {
          file(relativePath: { eq: "Prosjektlast.jpg" }) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fluid(maxWidth: 1440, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
      <div className="ocean7 imagebreaker">
        <Hero
          fluid={data.file.childImageSharp.fluid}
          posY="0%"
          posX="75%"
          height="50vh"
          width="100%"
          />
          <div className="flex center column basePad">
            <h2>About Ocean7 projects</h2>
            <p>Ocean7 Projects is an international project and heavy-lift carrier controlling a modern fleet of more than 25 vessels ranging from 3,500 to 17,500 DWT, with lifting capacity up to 500mtons, trading worldwide and DNV Quality System Certified. Ocean7 Projects operates through an extensive network of experienced, well-reputed agents globally in addition to Ocean7’s own offices in Denmark, Germany, the Netherlands, and Italy as well as the forceful United O7 setups in Malaysia, China, Japan, Dubai, the USA and Italy.
            <br/>
            <br/>
            <a href="https://www.ocean7projects.com" target="_blank">www.ocean7projects.com</a>
            </p>
          </div>
        </div>
      )
    }
  />
)}
export default ImageBreaker4;
