import React, {useState} from 'react';
import { useStaticQuery, graphql, Link } from "gatsby";
import {useSpring, useTrail, animated, interpolate} from 'react-spring';
import Img from "gatsby-image/withIEPolyfill";
import Logo from '../../../content/img/Logo.png';

const HeroLogo = () => {
  const [toggle, setToggle] = useState(0);
  const props = useSpring({
  opacity: 1,
  zIndex: 11,
  width: '100%',
  delay: 100,
  from: { opacity: 0, zIndex: 11, width: '100%' },
  config: { duration: 500 },
  })
  let items = [
    { name: 'United O7', location: 'Asia', link: '/united-07-asia'},
    { name: 'United O7', location: 'in Singapore', link: '/united-07-in-singapore'},
    { name: 'United O7', location: 'in China', link: '/united-07-in-china'},
    { name: 'United O7', location: 'in Japan', link: '/united-07-in-japan'},
    { name: 'United O7', location: 'Mideast', link: '/united-07-mideast'},
    { name: 'United O7', location: 'Americas', link: '/united-07-americas'},
    { name: 'United O7', location: 'Italy', link: '/united-07-italy'},
  ]
  const [trail, set] = useTrail(items.length, () => ({
    // trail: 1000,
    delay: 500,
    mass: 1,
    friction: 26,
    tension: 500,
    opacity: 1,
    sc: 1,
    x: "0%",
    y: "0px",
    skewX: 0,
    from: {
      opacity: 0,
      sc: 0.1,
      x: "10%",
      y: "50px",
      skewX: 20
    }
  }));
  const data = useStaticQuery(
    graphql`
      query {
        sitelogo: file(relativePath: { eq: "united07logo.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 450) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    ` )
    return(
      <animated.div className="heroLogoWrapper" key="test" style={props} className="flex center column">
        <div className="heroLogo" onClick={(()=> setToggle(toggle=== 0 ? 0 : 0))}>
          <Img fluid={data.sitelogo.childImageSharp.fluid}/>
        </div>
        {
          toggle === 0 ?
          <div className="united07 flex row center">
            {trail.map(({ sc, x, y, skewX, ...props }, i) => (
                <animated.div className="flex center row"
                key={i}
                style={{
                  ...props,
                  transform: interpolate(
                    [sc, x, y, skewX],
                    (sc, x, y, skewX) =>
                      `scale(${sc}) translate(${x}, ${y}) skewX(${skewX}deg)`
                  )
                }}>
                <Link to={items[i].link} target='_blank' className="flex column flexEnd wrap">
                  <h2>{items[i].name} {items[i].location}</h2>
                  </Link>
              </animated.div>
            ))}
          </div> :
          ''
        }
      </animated.div>
    )
  }
export default HeroLogo;
