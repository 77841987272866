import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";

const BackgroundSection = ({ className, ...props }) => {
  const data = useStaticQuery(
    graphql`
      query {
        united: file(relativePath: { eq: "heavyliftslogo.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heavylift: file(relativePath: { eq: "united07projectslogo.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const united = data.united.childImageSharp.fluid;
  const heavylift = data.heavylift.childImageSharp.fluid;

  if(props.ttt) {
    return (
        <Img
          className={className}
          fluid={united}
        />
    )
  } else {
    return (
        <Img
          className={className}
          fluid={heavylift}
          />
    )
  }
}

const United = () => {
  return (
    <div className="fleetLinks flex column basePad">
      <div className="fleetHeader flex row center">
        <h2 style={{marginRight: '1rem'}}>FLEET LINKS</h2>
      </div>
      <div className="united flex spaceBetween">
        <div className="flex center">
          <a href="https://www.unitedheavylift.de/fleet/" target="_blank">
            <BackgroundSection ttt />
        </a>
        </div>
        <div className="flex center">
          <a href="https://ocean7projects.com/#fleet-list" target="_blank">
            <BackgroundSection />
          </a>
        </div>

      </div>
    </div>
  )
}
export default United;
