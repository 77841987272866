import React, {useState} from 'react';
import { useSpring, animated } from 'react-spring';
import { Link, animateScroll as scroll } from "react-scroll";
import {FaUserGraduate,FaBriefcase,FaComments,FaInfoCircle,FaUsers,FaGlobeEurope,FaWrench} from 'react-icons/fa';
import {IoMdBusiness, IoMdNotifications} from 'react-icons/io';
import {GiCargoShip, GiCrane, GiBigWave} from 'react-icons/gi';

const FrontNav = () => {
  const [tooltipShowing, setTooltipShowing] = useState(0);
  const styles = useSpring({
    opacity: tooltipShowing
    ? 1 : 0,
    transition: '250ms'
  })
  return (
    <div className="frontNav flex center column basePad">
      <div className="flex center">
        <h1 style={{fontWeight: '700'}}>UNITED O7 <span style={{color: '#777', fontWeight: '100'}}>GLOBAL</span></h1>
      </div>
        <div className="buttons flex row center" style={{width: '100%'}}>
          <Link
            to="worldwide"
            alt="to section on company info"
            smooth={true}
            duration={400}>
            <button
            onMouseEnter={() => setTooltipShowing(1)}
            onMouseLeave={() => setTooltipShowing(0)}
            onMouseDown={() => setTooltipShowing(1)}
            onMouseUp={() => setTooltipShowing(0)}
            aria-label="Button to education list" ><FaInfoCircle size={26}/>
            { tooltipShowing === 1 ?
              <animated.div style={styles}><p>info</p></animated.div> : '' }
            </button>
          </Link>
          <Link
            to="fleetLinks"
            alt="company partners and owners"
            smooth={true}
            duration={400}>
            <button
            onMouseEnter={() => setTooltipShowing(2)}
            onMouseLeave={() => setTooltipShowing(0)}
            onMouseDown={() => setTooltipShowing(2)}
            onMouseUp={() => setTooltipShowing(0)}
            aria-label="Button to job list"><GiCargoShip size={26}/>
            { tooltipShowing === 2 ? <animated.div style={styles}><p>fleet</p></animated.div> : '' }
            </button>
          </Link>
          <Link
            to="reliability"
            alt="to section on United Heavylift"
            smooth={true}
            duration={400}>
            <button
            onMouseEnter={() => setTooltipShowing(3)}
            onMouseLeave={() => setTooltipShowing(0)}
            onMouseDown={() => setTooltipShowing(3)}
            onMouseUp={() => setTooltipShowing(0)}
            aria-label="Button to project list"><GiCrane size={26}/>
              { tooltipShowing === 3 ? <animated.div style={styles}><p>united heavylift</p></animated.div> : '' }
            </button>
          </Link>
          <Link
            to="ocean7"
            alt="to section on Ocean7 Projects"
            smooth={true}
            duration={400}>
            <button
            onMouseEnter={() => setTooltipShowing(4)}
            onMouseLeave={() => setTooltipShowing(0)}
            onMouseDown={() => setTooltipShowing(4)}
            onMouseUp={() => setTooltipShowing(0)}
            aria-label="Button to project list"><GiBigWave size={26}/>
              { tooltipShowing === 4 ? <animated.div style={styles}><p>ocean7 projects</p></animated.div> : '' }
            </button>
          </Link>
          {/*<Link
            to="professional"
            alt="to section on professional service"
            smooth={true}
            duration={400}>
            <button aria-label="Button to skill list"><FaInfoCircle size={26}/></button>
          </Link>*/}
          <Link
            to="stayUpdated"
            alt="to the newsletter and contacts section"
            smooth={true}
            duration={400}>
            <button
            onMouseEnter={() => setTooltipShowing(5)}
            onMouseLeave={() => setTooltipShowing(0)}
            onMouseDown={() => setTooltipShowing(5)}
            onMouseUp={() => setTooltipShowing(0)}
            aria-label="Button to contact information"><IoMdNotifications size={26}/>
            { tooltipShowing === 5 ? <animated.div style={styles}><p>notify</p></animated.div> : '' }
            </button>
          </Link>
        </div>
    </div>
  )
}
export default FrontNav;
