import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Hero from './Hero';

const ImageBreaker2 = () => {
  return (
    <StaticQuery
      query={graphql`
        query ImageBreaker2Query {
          file(relativePath: { eq: "United3.jpg" }) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fluid(maxWidth: 1440, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
      <div className="reliability imagebreaker">
      <Hero
        fluid={data.file.childImageSharp.fluid}
        posY="0%"
        posX="50%"
        height="50vh"
        width="100%"
        />
        <div className="flex center column basePad">
          <h2>About the United Group <br></br> and United Heavy Lift</h2>
          <p>
          Since our inception in 2015, the heavy lift specialists of the UNITED GROUP have emerged as pacesetters in heavy lift shipping. We launched four dedicated divisions, formed major international partnerships, and have grown a state-of-the-art fleet to become global leaders in the heavy lift sector. The UNITED GROUP is a suite of established stand-alone companies, working in close cooperation to offer industry-leading competence, experience, and know-how in their respective fields of business. Our mission is to ensure long-term business relationships through customer satisfaction.</p>
 
          <p>United Heavy Lift GmbH & Co. KG focuses on the transport of heavy and oversized project cargoes and is one of the leading providers of sea transportation services for heavy lift, breakbulk, and project cargo. Our services include chartering, cargo operations, project management, technical supervision, and engineering solutions for sophisticated marine transportation projects. Our team of over 120 highly-skilled employees secures smooth sea transportation and cargo operations from twelve offices around the globe.</p>
  
          <p>United Heavy Lift operates a fleet of over 20 modern heavy lift vessels and one of the youngest and most environmentally friendly MPP heavy lift fleets on the market. Our fleet includes seventeen F900 Eco-Lifters (built 2018-2022) with lifting capacities of up to 900 mtons. Two additional F900 Eco-Lifters are currently under construction and will join the fleet in 2024.</p>
  
          <p>The F900 Eco-Lifter class was designed and built based on the latest and most fuel-efficient propulsion technology. The carbon footprint of the F900 Eco-Lifter class is 30-50% less compared to the existing heavy lift fleet in the market. This makes the vessels one of the most modern and eco-friendly MPP tonnage in the industry. They also offer shallow draft, allowing us to call smaller and more remote ports. With adjustable tween decks and box-shaped cargo holds, the F900 Eco-Lifters are ideally situated to accommodate voluminous and heavy cargo. <br/><br/>

          <a href="https://www.unitedheavylift.de" target="_blank">www.unitedheavylift.de</a>

          </p>


          </div>
        </div>
      )
    }
  />
)}
export default ImageBreaker2;
